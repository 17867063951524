import React from "react"
import styled from "styled-components"

const Video = ({ url, title }) => {
    return (
        <IVWrapper>
            <div className="video-responsive">
                <iframe title={title} src={url} width="600" height="400" ></iframe>
            </div>
        </IVWrapper>
    )
}
const IVWrapper = styled.div`
.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`

export default Video