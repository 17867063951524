import React, { useState, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import Video from "./video"
import styled, { keyframes} from "styled-components"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { flash } from "react-animations"


const VidModal = ({ title, caption, link, alt, pic, align}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

	const controls = useAnimation()
    const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

    return(
	<motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 25 },
      }}
      transition={{ ease: "easeOut", duration: 1.25, delay: 0.35 }}
    >
    <VidModalWrapper>
            <div className="flex-min-height-100vh pos-rel">
				<div className="pos-rel padding-top-bottom-120 back text-color-black">
					{/* <!-- flex-container start --> */}
					<div className="container flex-container">
						{/* <!-- column start --> */}
						{align === 1 ? 
						<>
							<div className="six-columns padding-bottom-20 padding-top-120">
								<h2 className="subhead-l low-caption vid-right">{title}</h2>
								<p className="body-text-s low-caption vid-right">{caption}</p>
							</div>
							<div className="six-columns padding-bottom-20">
								<div className="flex-container pic-responsive outer-block" style={{backgroundImage:`url(${pic})`, backgroundSize:`cover`}}>
									<Button variant="light" onClick={handleShow} class="playable">
										<div className="play-text text-color-white" >
										(PLAY)
										</div>
									</Button>
								</div>
							</div>
						</>
						:
						<>
							<div className="six-columns padding-bottom-20">
								<div className="flex-container pic-responsive outer-block" style={{backgroundImage:`url(${pic})`, backgroundSize:`cover`}}>
									<Button variant="light" onClick={handleShow} class="playable">
										<div className="play-text text-color-white" >
										(PLAY)
										</div>
									</Button>
								</div>
							</div>
							<div className="six-columns padding-bottom-20 padding-top-120">
								<h2 className="subhead-l low-caption vid-left">{title}</h2>
								<p className="body-text-s low-caption vid-left">{caption}</p>
							</div>
						</>}
					</div>
				</div>
                <Modal 
                  show={show} 
                  size="lg"
                  centered
                  onHide={handleClose}>
                    <Video url={link} title={alt}/>
                </Modal>
            </div>
    </VidModalWrapper>
	</motion.div>
    )
}
const VidModalWrapper = styled.div`
.outer-block{
  position: relative;
  overflow: hidden;
}
.play-text{
	margin: auto;
	text-align: center;
	justify-content: center;
	position: absolute;
	height: 100%;
	width: 100%;
	top:50%;
	:hover{
		animation: 2s ${keyframes`${flash}`} infinite;
	}
}

.playable{
	height: 100%;
}

.low-caption{
	top: 5%;
	margin-right: 8%;
	margin-left: 15%;
}

.vid-right{
	text-align: right!important;
}
.vid-left{
	text-align: left!important;
}

.pic-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
	border-radius 25px;
    height: 0;
	.playable{
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
	  }
  }
/* 17.10. Play button
   ========================================================================== */

.play-button {
	display: block;
	width: 32px;
	height: 44px;
	padding: 18px 20px 18px 28px;
	position: absolute;
	left: 50%;
	top:  50%;
	-webkit-box-sizing: content-box;
	        box-sizing: content-box;
	border: 1px solid rgba(250, 250, 250, .2);
	border-radius: 50%;
	-webkit-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.play-button:before,
.play-button:after {
	content: '';
	width:  100%;
	height: 100%;
	position: absolute;
	left: 0;
	top:  0;
	opacity: 0;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 30px rgba(250, 250, 250, .3);
	        box-shadow: 0 0 0 30px rgba(250, 250, 250, .3);
	background-color: rgba(250, 250, 250, .3);
	-webkit-transform: scale(1);
	    -ms-transform: scale(1);
	        transform: scale(1);
	-webkit-animation: 1.5s pulse-anim cubic-bezier(.17, .85, .438, .99) infinite;
	        animation: 1.5s pulse-anim cubic-bezier(.17, .85, .438, .99) infinite;
}

.play-button:after {
	-webkit-animation-duration: 3s;
	        animation-duration: 3s;
	-webkit-animation-delay: .15s;
	        animation-delay: .15s;
}

.play-button__inner {
	display: block;
	width:  0;
	height: 0;
	border-left: 32px solid rgba(250, 250, 250, .5);
	border-top:    22px solid transparent;
	border-bottom: 22px solid transparent;
}
`

export default VidModal